import React from "react";

import ErrorPage from "../../../pages/ErrorPage";
import { logError } from "../../../libs/errors/logError";

type ErrorBoundaryState = {
  error?: Error;
  hasError: boolean;
};

type Options = {
  ErrorComponent?: React.ComponentType<{ error?: Error }>;
};

const withErrorBoundary = <T extends object>(
  WrappedComponent: React.ComponentType<T>,
  options?: Options,
): React.ComponentType<T> =>
  class E extends React.PureComponent<T, ErrorBoundaryState> {
    state = {
      error: undefined,
      hasError: false,
    };

    componentDidCatch(error: Error) {
      // Display fallback UI
      console.log("Catched error !");
      this.setState({ error, hasError: true });
      // You can also log the error to an error reporting service
      logError(error, null, "second_level_error");
    }

    render() {
      const ErrorComponent = options?.ErrorComponent ?? ErrorPage;

      if (this.state.hasError) {
        return <ErrorComponent error={this.state.error} />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };

export default withErrorBoundary;
