import type { CSSProperties } from "react";
import React from "react";

export type Props = {
  style?: CSSProperties;
};

const faviconSource =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAADwBAMAAADMe/ShAAAAFVBMVEUAAAD/AP9SHrCIVefEnfrEnvr///+Ev+YkAAAAAnRSTlMAAQGU/a4AAAABYktHRAZhZrh9AAAAfUlEQVR42u3QQQ0AIQADQSycBSxg4Szg3woOCKTwYsbApi0FAAAAAAAA4LIeEBYWFhYWFhYWFhYWFhYWFhZ+MPxN/VNRuAWiM4WFhYWFhYWFhYWFhYWFhYWFhbfCNRBtEhYWFhYWFhYWFhYWFhYWFhYWPhgGAAAAAAAAWDAAdMo6G9ifCXEAAAAASUVORK5CYII=";

const OneChainFavicon = ({ style }: Props) => (
  <img
    alt="OneChain favicon"
    className="ovrsea-favicon"
    data-testid="OneChainFavicon"
    src={faviconSource}
    style={style}
  />
);

//Loadable
export default OneChainFavicon;
