import { useMutation } from "@apollo/client";
import type { SupportedLocales } from "@ovrsea/i18n";
import { useEffect } from "react";

import posthog from "posthog-js";
import { datadogRum } from "@datadog/browser-rum";
import { useSession } from "../../components/App/SessionProvider";
import { useAccountInfo } from "../../components/Layout/HOCs/withAccountInfo";
import { useLocale } from "../../hooks/intl/useLocale";
import { useWindowProperty } from "../../hooks/useWindowProperty";
import { logError } from "../../libs/errors/logError";
import type {
  Language,
  ShowSurveyForUserMutation,
  ShowSurveyForUserMutationVariables,
} from "../../api.generated";
import { useRouteLocation } from "../../utils/routes/reactRouter";
import {
  isEmployee,
  isUser,
  isUserFromOvrsea,
} from "../../utils/typescript/isUserOrEmployee";
import { showSurveyForUserMutation } from "../users/mutations";

type BasicDatadogUser = {
  email: string;
  id: string;
  name: string;
  organizationName: string;
};

type EmployeeDatadogUser = {
  office: string;
  personType: "employee";
  role: string;
  squad: null | string;
} & BasicDatadogUser;

type UserDatadogUser = {
  clientName: string;
  personType: "user";
} & BasicDatadogUser;

type LogisticAgentUser = {
  logisticAgentName: string;
  logisticAgentType: string;
  personType: "logisticAgent";
} & BasicDatadogUser;

type DatadogUser = EmployeeDatadogUser | LogisticAgentUser | UserDatadogUser;

type PersonFromSession = Exclude<ReturnType<typeof useAccountInfo>, undefined>;

const mapUser = (person: PersonFromSession) => {
  if (isUser(person)) {
    return {
      clientName: person.client.name,
      email: person.email,
      id: person.id,
      name: person.name,
      organizationName: person.client.name,
      personType: "user",
    } satisfies UserDatadogUser;
  }

  if (isEmployee(person)) {
    return {
      email: person.email,
      id: person.id,
      name: person.name,
      office: person.ovrseaOffice.id,
      organizationName: "OVRSEA",
      personType: "employee",
      role: person.role,
      squad: person.squadName,
    } satisfies EmployeeDatadogUser;
  }

  return {
    email: person.email,
    id: person.id,
    logisticAgentName: person.logisticAgent.name,
    logisticAgentType: person.logisticAgent.type,
    name: person.name,
    organizationName: person.logisticAgent.name,
    personType: "logisticAgent",
  } satisfies LogisticAgentUser;
};

export const usePosthog = () => {
  const user = useAccountInfo();

  const { pathname, search } = useRouteLocation(); // new

  useEffect(() => {
    const shouldInitPosthog = __ENABLE_TRACKERS__;

    if (!shouldInitPosthog) {
      return;
    }
    // new
    posthog.capture("$pageview");
  }, [pathname, search]);

  useEffect(() => {
    const shouldInitPosthog = __ENABLE_TRACKERS__;

    if (!shouldInitPosthog) {
      return;
    }
    posthog.init(__POSTHOG_PROJECT_ID__, {
      api_host: "https://app.posthog.com",
      session_recording: {
        maskAllInputs: false,
      },
    });
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    const shouldInitPosthog = __ENABLE_TRACKERS__;

    if (!shouldInitPosthog) {
      return;
    }

    if (isUser(user)) {
      const userProperties = {
        Client: user.client.name,
        Languge: user.language,
        "Owner office": user.client.salesOwner?.ovrseaOffice.id,
        Role: user.role,
        email: user.email,
      };

      posthog.identify(user.email, userProperties);
      posthog.group("client", user.client.name);

      return;
    }

    if (isEmployee(user)) {
      const employeeProperties = {
        Name: user.name,
        Office: user.ovrseaOffice.id,
        Role: user.role,
        Squad: user.squadName,
        email: user.email,
      };

      posthog.identify(user.email, employeeProperties);
      posthog.group("employeeRole", user.role);

      return;
    }
  }, [user]);
};

/*
Front chat : enable front chat for hermes users

*/

export const useChat = () => {
  const { session } = useSession();
  const chat = useWindowProperty("FrontChat");

  useEffect(() => {
    if (__ENABLE_TRACKERS__ && chat && session && __HERMES__) {
      const user = session.person;

      const userForChat = mapUser(user);

      try {
        chat("init", {
          chatId: __FRONT_CHAT_ID__,
          customFields: {
            organizationName: userForChat.organizationName,
            type: userForChat.personType,
          },
          email: user.email,
          name: user.name,
          useDefaultLauncher: true,
          userHash: session.frontChatToken,
        });
      } catch (err: any) {
        logError(err, "front", "front_init");
      }
    }
  }, [session, chat]);
};

/*

Delighted tracker : used for NPS Tracking

*/

const showSurvey = (
  user: {
    client: { name: string };
    email: string;
    isDecisionMaker: boolean;
    name: string;
    role: string;
  },
  locale: SupportedLocales,
) => {
  const metabaseClientDashboardURL = `https://metabase.ovrsea.com/dashboard/324-clients-satisfaction?client_name=${encodeURIComponent(
    user.client.name,
  )}`;

  if (window.delighted?.survey) {
    return window.delighted.survey({
      email: user.email,
      name: user.name,
      properties: {
        client: user.client.name,
        is_decision_maker: user.isDecisionMaker,
        locale,
        role: user.role,
        satisfaction: "Click to see metabase dashboard",
        satisfaction_url: metabaseClientDashboardURL,
      },
    });
  }
};

const useShowSurveyForUserMutation = () =>
  useMutation<ShowSurveyForUserMutation, ShowSurveyForUserMutationVariables>(
    showSurveyForUserMutation,
  );

const useSurvey = () => {
  const user = useAccountInfo();
  const locale = useLocale();
  const [showSurveyForUser] = useShowSurveyForUserMutation();

  useEffect(() => {
    if (user && __HERMES__ && __ENABLE_TRACKERS__) {
      try {
        void showSurveyForUser({
          variables: {
            id: user.id,
          },
        }).then(
          (response) =>
            response.data?.showSurveyForUser &&
            showSurvey(response.data.showSurveyForUser, locale),
        );
      } catch (err: any) {
        console.log("Survey display failed.");
        console.log(err);
      }
    }
  }, [user]);
};

const mapSessionUserToDelightedUser = (
  user: NonNullable<ReturnType<typeof useSession>["session"]>["person"],
) => {
  switch (user.__typename) {
    case "Employee": {
      return {
        organization_name: "OVRSEA",
        organization_type: "freight_forwarder",
      };
    }
    case "LogisticAgentUser": {
      return {
        organization_name: user.logisticAgent.name,
        organization_type: user.logisticAgent.type,
      };
    }
    case "User": {
      return {
        organization_name: user.client.name,
        organization_type: "client",
      };
    }
  }

  throw new Error("Invalid user type");
};

export const useOneChainSurvey = () => {
  const { session } = useSession();
  const locale = useLocale();

  const delighted = useWindowProperty("delighted");

  useEffect(() => {
    const askUserForSurvey = () => {
      if (session?.person && delighted) {
        return delighted?.survey({
          email: session.person.email,
          name: session.person.name,
          properties: {
            locale,
            ...mapSessionUserToDelightedUser(session.person),
          },
        });
      }
    };

    askUserForSurvey();
  }, [session]);
};

type BasicUserForStonly = {
  email: string;
  pictureUrl: null | string;
};

type EmployeeUserForStonly = {
  __typename: "Employee";
  role: string;
} & BasicUserForStonly;

type ClientUserForStonly = {
  __typename: "User";
  language: Language | null;
  role: string;
  stonlyProps: {
    hasAtLeastOneFinishedShipment: boolean;
    isUserFromLiveAccount: boolean;
  };
} & BasicUserForStonly;

type UserForStonly = ClientUserForStonly | EmployeeUserForStonly;

/*

Stonly : used for onboarding

*/

export const useStonly = () => {
  const user = useAccountInfo();
  const EXPERIMENTAL_USERS = [
    "nader.zaim@ovrsea.com",
    "remy.vandyk@ovrsea.com",
    "romain.magne@ovrsea.com",
    "donatella.delinares@ovrsea.com",
    "lea.gilles@ovrsea.com",
  ];

  useEffect(() => {
    if (!user) {
      return;
    }

    const isExperimentalUser: boolean = EXPERIMENTAL_USERS.includes(
      user.email.replace("+hermes", ""),
    );

    const extractGeneralFields = (user: UserForStonly) => ({
      "has-profile-picture": !!user.pictureUrl,
      "is-experimental-user": isExperimentalUser,
    });

    const extractEmployeeOrOvrseaUserFields = (
      user: ClientUserForStonly | EmployeeUserForStonly,
    ) => ({
      "is-user-from-OVRSEA": true,
      "ovrsea-user-role": user.role,
    });

    const extractUserFields = (user: ClientUserForStonly) => ({
      "has-finished-shipment": user.stonlyProps.hasAtLeastOneFinishedShipment,
      "is-user-from-OVRSEA": false,
      "is-user-from-live-account": user.stonlyProps.isUserFromLiveAccount,
      language: user.language,
    });

    const extractFieldsFromUser = (user: UserForStonly) => {
      if (isEmployee(user) || isUserFromOvrsea(user)) {
        return {
          ...extractGeneralFields(user),
          ...extractEmployeeOrOvrseaUserFields(user),
        };
      }
      if (isUser(user)) {
        return {
          ...extractGeneralFields(user),
          ...extractUserFields(user),
        };
      }

      return {};
    };

    try {
      if (window.StonlyWidget && user.__typename !== "LogisticAgentUser") {
        window.StonlyWidget(
          "identify",
          user.email,
          extractFieldsFromUser(user),
        );
        if (isUser(user)) {
          window.StonlyWidget("setWidgetLanguage", user.language);
        }
      }
    } catch (err: any) {
      console.log("Stonly failed to initialize.");
      console.log(err);
    }
  }, [window.StonlyWidget, user?.email]);
};

/*

Datadog user initialisation

*/

/*

Datadog tracker : used on both Atlas and Hermes for bug reporting


*/

const setDatadogUser = (user: DatadogUser) => {
  datadogRum.setUser({
    ...user,
    email: user.email,
    id: user.id,
    name: user.name,
  });
};

export const useDatadogUser = () => {
  const user = useAccountInfo();

  return useEffect(() => {
    if (user && __ENABLE_TRACKERS__) {
      try {
        setDatadogUser(mapUser(user));
      } catch (err: any) {
        console.log("One or more analytic tool failed to initialize.");
        console.log(err);
        logError(err, null, "tracker");
      }
    }
  }, [user]);
};

/*

Main hook for initiliazing trackers

*/

export const useTracker = () => {
  useChat();
  useSurvey();
  useStonly();
  usePosthog();
  useDatadogUser();
};
