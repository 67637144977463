import { onError } from "@apollo/client/link/error";
import type { ServerError } from "@apollo/client";
import { logError } from "../../libs/errors/logError";

const extractErrorMessage = (error: any): null | string =>
  "message" in error ? error.message : String(error);

const formatClientError = ({
  error,
  operationName,
}: {
  error: any;
  operationName: string;
}) => {
  const withErrorMessage = extractErrorMessage(error)
    ? `with error message \`${extractErrorMessage(error)}\``
    : "";

  return `Operation ${operationName} failed ${withErrorMessage}`;
};

export const NOT_AUTHENTICATED_CODE = "not_authenticated";

export const configureErrorLink = (
  logoutCallback: (...args: unknown[]) => unknown,
) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const context = {
      operationName: operation.operationName,
      variables: operation.variables,
    };

    // @ts-expect-error
    if (networkError?.response) {
      logError(networkError as ServerError, context, "network-error");
    }

    if (graphQLErrors) {
      // const filteredGraphQLErrors = filterErrorsByMessage(graphQLErrors);

      graphQLErrors.map((error) =>
        logError(
          new Error(
            formatClientError({
              error,
              operationName: operation.operationName,
            }),
          ),
          {
            error,
            operationName: operation.operationName,
            variables: operation.variables,
          },
          "graphql-error",
        ),
      );

      const authenticationErrorExists = graphQLErrors.some(
        ({ extensions }) => extensions?.code === NOT_AUTHENTICATED_CODE,
      );

      if (authenticationErrorExists) {
        logoutCallback();
      }
    }
  });
