import type { PropsWithChildren } from "react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import MaintenancePage from "./MaintenancePage";

const POLLING_INTERVAL = 120 * 1000;

type Status = "off" | "on";
type AllStatus = { atlas: Status; hermes: Status };

const fetchStatus = async (): Promise<AllStatus> => {
  try {
    if (!__STATUS_URL__ || __IS_DEV__) {
      return {
        atlas: "on",
        hermes: "on",
      };
    }

    const result = await axios.get<AllStatus>(__STATUS_URL__);

    return result.data;
  } catch (e) {
    console.log("Error in status call: ", e);

    return {
      atlas: "on",
      hermes: "on",
    };
  }
};

const useMaintenanceStatus = () => {
  const [status, setStatus] = useState<Status>("on");

  const updateStatus = async () => {
    const allStatus = await fetchStatus();

    setStatus(__HERMES__ ? allStatus.hermes : allStatus.atlas);
  };

  useEffect(() => {
    (async () => {
      await updateStatus();
    })();
    const id = setInterval(updateStatus, POLLING_INTERVAL);

    return () => clearInterval(id);
  }, []);

  return status;
};

export const StatusContainer = ({
  branding,
  children,
}: PropsWithChildren<{ branding: "onechain" | "ovrsea" }>) => {
  const status = useMaintenanceStatus();

  if (status === "off") {
    return <MaintenancePage branding={branding} />;
  }

  return <>{children}</>;
};
