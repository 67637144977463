import { ApolloClient, ApolloLink } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { compact } from "lodash-es";
import { useMemo } from "react";

import { useAuthenticationContainer } from "../../components/Auth/AuthenticationContext";
import { configureErrorLink } from "./errorLink";
import { appCache } from "./cache";
import confirmQuitWhenMutationOngoingLink from "./confirmQuitWhenMutationOngoingLink";

const useLink = () => {
  const { authToken, logout } = useAuthenticationContainer();
  const link = createUploadLink({
    headers: {
      "Apollo-Require-Preflight": "true",
      Authorization: authToken as string,
    },
    uri: __SERVERLESS_API_URL__,
  });
  const errorLink = configureErrorLink(logout);

  return useMemo(
    () => compact([confirmQuitWhenMutationOngoingLink, errorLink, link]),
    [authToken],
  );
};

/* Warning: we use useMemo to guarranty "apolloClient" identity, which is not advised by React documentation

You may rely on useMemo as a performance optimization, not as a semantic guarantee. In the future, React may choose to “forget” some previously memoized values and recalculate them on next render, e.g. to free memory for offscreen components. Write your code so that it still works without useMemo — and then add it to optimize performance.

*/

export const useAtlasApolloClient = () => {
  const link = useLink();

  const atlasClient = new ApolloClient({
    assumeImmutableResults: true,
    cache: appCache,

    connectToDevTools: true,
    defaultOptions: {
      query: {
        fetchPolicy: "cache-first",
      },
      watchQuery: {
        fetchPolicy: "cache-and-network",
        // this applies when a query is updated by ANOTHER query. This does not apply if the component is remounted I guess
        // https://github.com/apollographql/apollo-client/issues/7938
        nextFetchPolicy: "cache-first",
      },
    },
    link: ApolloLink.from(link),
    name: "atlas",
  });

  return useMemo(() => atlasClient, [link]);
};
