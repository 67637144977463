import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import type { PropsWithChildren } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { useOvrseaNavigate } from "../../utils/routes/reactRouter";
import type { AuthenticationInterface } from "./AuthenticationContext";
import { AuthenticationContext } from "./AuthenticationContext";

const useReactAuth0Authentication = (
  loginType: "popup" | "redirect" = "redirect",
): AuthenticationInterface => {
  const {
    error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithPopup,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0();

  const [authToken, setAuthToken] = useState<string>();

  const fetchToken = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: __AUTH0_AUDIENCE__,
      },
    });

    setAuthToken(token);
  };

  useEffect(() => {
    if (error) {
      (async () => {
        await logout();
      })();
    }
    if (isAuthenticated) {
      (async () => {
        await fetchToken();
      })();
    }
  }, [isAuthenticated, error]);

  return useMemo(
    () => ({
      authToken: `Bearer ${authToken}`,
      isAuthenticated,
      isLoading,
      login:
        loginType === "redirect"
          ? () =>
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
              })
          : loginWithPopup,
      logout,
      user,
    }),
    [
      authToken,
      isAuthenticated,
      isLoading,
      loginWithRedirect,
      loginWithPopup,
      logout,
      user,
    ],
  );
};

const AuthenticationProvider = ({ children }: PropsWithChildren<object>) => {
  return (
    <AuthenticationContext.Provider value={useReactAuth0Authentication()}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const ReactAuth0Provider = ({ children }: PropsWithChildren<object>) => {
  const navigate = useOvrseaNavigate();

  return (
    <Auth0Provider
      authorizationParams={{
        audience: __AUTH0_AUDIENCE__,
        redirectUri: window.location.origin,
      }}
      clientId={__AUTH0_CLIENT_ID__}
      domain={__AUTH0_DOMAIN__}
      onRedirectCallback={(appState) => {
        const uri = appState?.returnTo ?? window.location.pathname;

        if (uri) {
          navigate(uri);
        }
      }}
    >
      <AuthenticationProvider>{children}</AuthenticationProvider>
    </Auth0Provider>
  );
};

const PopUpAuthenticationProvider = ({
  children,
}: PropsWithChildren<object>) => {
  return (
    <AuthenticationContext.Provider
      value={useReactAuth0Authentication("popup")}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const PopUpReactAuth0Provider = ({
  children,
}: PropsWithChildren<object>) => {
  return (
    <Auth0Provider
      authorizationParams={{
        audience: __AUTH0_AUDIENCE__,
      }}
      clientId={__AUTH0_CLIENT_ID__}
      domain={__AUTH0_DOMAIN__}
    >
      <PopUpAuthenticationProvider>{children}</PopUpAuthenticationProvider>
    </Auth0Provider>
  );
};
