import { gql } from "@apollo/client/core";
import { userForCheckSessionFragment } from "../users/fragments";
import { employeeForCheckSessionFragment } from "../employees/fragment";

export const checkSessionQuery = gql`
  query checkSession {
    checkSession {
      algoliaSearchKey
      frontChatToken
      person {
        ... on User {
          stonlyProps {
            hasAtLeastOneFinishedShipment
            isUserFromLiveAccount
          }
          ...userForCheckSessionFields
        }
        ... on Employee {
          ...employeeForCheckSessionFields
          squadName
        }
        ... on LogisticAgentUser {
          id
          createdAt
          updatedAt
          email
          firstName
          language
          lastName
          logisticAgent {
            id
            clients {
              id
              name
            }
            logoUrl
            managedWarehouses {
              id
            }
            name
            type
          }
          name
          phone
          pictureUrl
          role
          shouldAcceptOneChainTermsOfUse
        }
      }
      token
    }
  }
  ${userForCheckSessionFragment}
  ${employeeForCheckSessionFragment}
`;
