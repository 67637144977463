import { gql } from "@apollo/client/core";
import { notesFragment } from "../notes/fragments";

const usersInfoFragment = gql`
  fragment usersInfo on User {
    id
    createdAt
    updatedAt
    email
    invitation {
      id
      invitToken
    }
    isDecisionMaker
    isUserUsingOneChain
    language
    name
    phone
    pictureUrl
    role
    status
  }
`;

export const clientScalarFragment = gql`
  fragment clientScalarFields on Client {
    id
    createdAt
    updatedAt
    approvedExporter
    billingAddress
    billingAddressCountry
    billingEmailAddresses
    billingNote
    creditInsuranceInformation {
      allianzTradeId
      authorizedCreditLimit
      guaranteedCreditLimit
      guaranteeType
    }
    currency
    customs
    customsBondUsa
    debtCollectionEmailAddresses
    debtCollectionNote
    decisionMakerPerceptionScore
    email
    eoriNumber
    eur1
    industry
    isForbiddenFromCreatingQuotes
    isUnderFDARegulation
    marginBy
    name
    nPendingQuotations
    nQuotations
    nShipments
    paymentDelayDays
    paymentMode
    phone
    productsAdditionalAttributeA
    productsAdditionalAttributeB
    productsAdditionalAttributeC
    purchaseOrdersAdditionalAttributeA
    purchaseOrdersAdditionalAttributeB
    purchaseOrdersAdditionalAttributeC
    purchaseOrdersAdditionalAttributeD
    purchaseOrdersAdditionalAttributeE
    purchaseOrdersAdditionalAttributeF
    purchaseOrdersAdditionalAttributeG
    salesforceId
    securing
    shipmentsNumber
    siretNumber
    t1
    vatNumber
    vatReverseCharge
  }
`;

export const clientTableFragment = gql`
  fragment clientTableFields on Client {
    ...clientScalarFields
    users {
      ...usersInfo
    }
  }
  ${usersInfoFragment}
  ${clientScalarFragment}
`;

export const detailedClientFragment = gql`
  fragment detailedClientFields on Client {
    ...clientScalarFields
    commercialRelationshipInformation {
      accountStatus
      reasonForBlocker
      reportedCommercialRelationshipBlocker
    }
    notes {
      ...notesFields
    }
    opsOwner {
      id
      name
      phone
      pictureUrl
    }
    salesOwner {
      id
      name
      phone
      pictureUrl
    }
    users {
      ...usersInfo
    }
  }
  ${usersInfoFragment}
  ${clientScalarFragment}
  ${notesFragment}
`;

export const clientSimpleScalarFragment = gql`
  fragment clientSimpleScalarFields on Client {
    id
    approvedExporter
    billingAddress
    billingAddressCountry
    billingEmailAddresses
    billingNote
    currency
    customs
    customsBondUsa
    debtCollectionEmailAddresses
    debtCollectionNote
    eoriNumber
    eur1
    isUnderFDARegulation
    marginBy
    name
    nPendingQuotations
    nQuotations
    nShipments
    paymentDelayDays
    paymentMode
    productsAdditionalAttributeA
    productsAdditionalAttributeB
    productsAdditionalAttributeC
    purchaseOrdersAdditionalAttributeA
    purchaseOrdersAdditionalAttributeB
    purchaseOrdersAdditionalAttributeC
    purchaseOrdersAdditionalAttributeD
    purchaseOrdersAdditionalAttributeE
    purchaseOrdersAdditionalAttributeF
    purchaseOrdersAdditionalAttributeG
    securing
    shipmentsNumber
    siretNumber
    t1
    vatNumber
    vatReverseCharge
  }
`;

export const clientForSimplifiedBillingFragment = gql`
  fragment clientForSimplifiedBillingFields on Client {
    id
    billingAddress
    billingEmailAddresses
    name
    users {
      id
      email
      role
    }
    vatNumber
  }
`;

export const clientForQueryOrderFragment = gql`
  fragment clientForQueryOrderFields on Client {
    ...clientScalarFields
    users {
      id
      email
      language
      name
      pictureUrl
      role
      status
    }
  }
  ${clientScalarFragment}
`;

export const clientForQueryOrderWithClientUserFragment = gql`
  fragment clientForQueryOrderWithClientUserFields on Client {
    ...clientSimpleScalarFields
    commercialRelationshipInformation {
      accountStatus
    }
    isForbiddenFromCreatingQuotes
    isTmsClient
    users {
      id
      client {
        ...clientSimpleScalarFields
      }
      email
      language
      name
      phone
      pictureUrl
      role
      status
    }
    userSubscriptions {
      id
      user {
        id
      }
    }
  }
  ${clientSimpleScalarFragment}
`;

const allowedOrderOwnersFragment = gql`
  fragment allowedOrderOwnersFields on AllowedOrderOwners {
    all {
      id
      name
    }
    default {
      id
      name
    }
  }
`;

export const clientForUserOnCheckSessionFragment = gql`
  fragment clientForUserOnCheckSessionFields on Client {
    id
    allowedOrderOwners {
      ...allowedOrderOwnersFields
    }
    backupSalesOwner {
      id
      employee {
        id
        email
        name
        phone
        pictureUrl
      }
    }
    billingAddressCountry
    connections {
      buyers {
        account {
          id
          name
        }
      }
    }
    insuranceSpecificities {
      coverage
    }
    isForbiddenFromCreatingQuotes
    isSupplierOnly
    logisticAgents {
      id
      logoUrl
      name
      type
    }
    managedWarehouses {
      id
    }
    name
    nQuotations
    nShipments
    opsOwner {
      id
      email
      name
      phone
      pictureUrl
      status
    }
    productsAdditionalAttributeA
    productsAdditionalAttributeB
    productsAdditionalAttributeC
    productSource
    purchaseOrdersAdditionalAttributeA
    purchaseOrdersAdditionalAttributeB
    purchaseOrdersAdditionalAttributeC
    purchaseOrdersAdditionalAttributeD
    purchaseOrdersAdditionalAttributeE
    purchaseOrdersAdditionalAttributeF
    purchaseOrdersAdditionalAttributeG
    salesOwner {
      id
      email
      name
      ovrseaOffice {
        id
      }
      phone
      pictureUrl
      status
    }
  }
  ${allowedOrderOwnersFragment}
`;
