import React from "react";
import { messages, useIntl } from "@ovrsea/i18n";
import { Helmet } from "react-helmet";
import { logError } from "../../libs/errors/logError";
import { useRouteLocation } from "../../utils/routes/reactRouter";
import { ROUTES } from "../../utils/routes/routeDefinitions";

const extractFirstPathPortion = (routePath: string) => {
  const [, routeName] = routePath.split("/");

  return routeName;
};

const extractForeseaName = (routePath: string) => {
  const [, , , foreseaName] = routePath.split("/");

  return foreseaName;
};

const extractCustomsClearanceReference = (routePath: string) => {
  const [, , , , customsClearanceReference] = routePath.split("/");

  return customsClearanceReference;
};

type Title = keyof typeof messages.titles;

const hasTitleMessageFor = (route?: string): route is Title =>
  Boolean(route && route in messages.titles);

const AppTitle = ({ title }: { title: string }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

const AppRouteTitle = () => {
  const { pathname } = useRouteLocation();

  const { formatMessage } = useIntl();

  const generateDocumentTitle = () => {
    if (pathname === "/") {
      return formatMessage(messages.titles.dashboard);
    }

    if (pathname === "/shipments/new/booking-request") {
      return formatMessage(messages.titles["booking-request"]);
    }

    if (pathname === "/tracking/new") {
      return formatMessage(messages.titles.trackingOrder);
    }
    if (pathname === "/order-preparation-request/new") {
      return formatMessage(messages.titles.orderPreparation);
    }

    if (pathname.includes("gen-ai-debug")) {
      return "Gen AI Debug";
    }

    if (
      pathname.startsWith(`/${ROUTES.CUSTOMS_CLEARANCE_ROUTE_SUFFIX}/show/`)
    ) {
      const reference = extractCustomsClearanceReference(pathname);

      if (reference) {
        return reference;
      }
    }

    if (pathname.startsWith("/customs-clearance")) {
      return formatMessage(messages.titles.customs);
    }

    if (pathname.startsWith("/terms-of-use")) {
      return formatMessage(messages.titles.termsOfUse);
    }

    const firstPathPortion = extractFirstPathPortion(pathname);

    if (
      firstPathPortion === "shipments" ||
      firstPathPortion === "quotations" ||
      firstPathPortion === "orders"
    ) {
      const foreseaName = extractForeseaName(pathname);

      if (foreseaName) {
        return foreseaName;
      }
    }

    if (firstPathPortion === "order-preparation-request") {
      return "order-preaparation-request";
    }

    if (firstPathPortion === "e-shipping") {
      return "e-shipping";
    }

    if (pathname.includes("playground")) {
      return "Hello ginger";
    }

    if (pathname.includes("traffics")) {
      return formatMessage(messages.defaults.navigation.traffics);
    }

    if (hasTitleMessageFor(firstPathPortion)) {
      return formatMessage(messages.titles[firstPathPortion]);
    }

    logError(
      new Error("no title specified"),
      null,
      "withDocumentTitle: getDocumentTitle",
    );

    return formatMessage(messages.titles.default);
  };

  const title = generateDocumentTitle();

  return <AppTitle key={title} title={title} />;
};

export { AppRouteTitle, AppTitle };
